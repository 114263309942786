








import Vue from "vue";

export default Vue.extend({
  metaInfo: {
    title: "Вход в систему"
  },
  props: {
    from: {
      type: String,
      default: "/"
    },
    to: {
      type: String,
      default: "/"
    }
  },
  mounted() {
    if (this.$cStore.user.isLoggedIn) {
      this.$router.replace(this.to);
    } else {
      this.$modal.show("login", {
        stopClose: true,
        to: this.to,
        from: this.from
      });
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$modal.hide("login", { stopClose: false });
    next();
  }
});
